import React, { useState } from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import "./styles/sectionThree.css";
import Carousel from 'better-react-carousel'
import frameOne from '../../assets/frameOne.svg'
import pdfFile from '../../assets/its31.pdf'


export default function SectionThree() {
  const { t } = useTranslation()


  const [isOpen, setOpen] = useState(false)
  const handleClick = () => {
    setOpen(!isOpen)
  }
  const elemAnimate = {
    offscreen: {
      translateX: -1500,
    },
    onscreen: {
      translateX: 0,
      transition: {
        type: 'tween',
        bounce: 0.4,
        duration: 1
      },
    }
  }
  const extramAnimate = {
    offscreen: {
      y: -1500,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        bounce: 0.4,
        type: 'tween',
        duration: 1,
      }
    }
  }

  return (
    <section id="portfolio" className="section-three">
      <div
        className="section-three_inside">
        <div className="section-three_inside-block">
          <h3>{t("products")}</h3>
          <div className="grid-container">
            <motion.div
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={{ once: true, amount: 0.2 }}
              transition={{
                staggerChildren: 0.2
              }}
              className="angry-grid"
            >
              <motion.div variants={elemAnimate} id="item-0">
                <motion.div whileInView={{ scale: 0.85 }} whileHover={{ scale: 0.9 }} className="content-block">
                  <div className="content-title">
                    <h2 >Интеграция с КБ Ишеним</h2>
                    <h5>Разработка веб-системы для обмена данными с информационной системой Кредитного бюро «Ишеним».</h5>
                    <div className="content-list">
                      <div className="content-text">
                        <h5>СИСТЕМА </h5>
                        <p>Система для обмена данными и журналирования действий</p>
                      </div>
                      <div className="content-text">
                        <h5>API ДЛЯ ОБМЕНА ДАННЫМИ </h5>
                        <p>Протокол для безопасного обмена данными.</p>
                      </div>
                    </div>
                  </div>
                </motion.div>
              </motion.div>
              <motion.div
                variants={elemAnimate}
                id="item-1">

                <motion.div whileInView={{ scale: 0.85 }} whileHover={{ scale: 0.9 }} className="content-block">
                  <div className="content-title">
                    <h2 >Телеграм боты</h2>
                    <h5>Создание чат-бота с автоматическим предоставлением информации и поддержкой
                    </h5>
                  </div>
                </motion.div>

              </motion.div>
              <motion.div
                variants={elemAnimate}
                id="item-3">

                <motion.div whileInView={{ scale: 0.85 }} whileHover={{ scale: 0.9 }} className="content-block">
                  <div className="content-title">
                    <h2 >Оцифровка документов</h2>
                    <h5>Преобразование рукописных документов путем сканирования в электронный формат</h5>
                  </div>
                  <div className="content-list">
                    <div className="content-text">
                      <h5>WEB СИСТЕМА</h5>
                      <p>Система для загрузки и хранения сканированных документов.</p>
                    </div>
                    <div className="content-text">
                      <h5>БИЛЛИНГ</h5>
                      <p>Система для учета выполненных работ пользователей и оплаты труда.</p>
                    </div>
                    <div className="content-text">
                      <h5>НЕЙРОННАЯ СЕТЬ</h5>
                      <p>Система для опознания и разделения рукописных текстов на отдельные слова.</p>
                    </div>
                    <div className="content-text">
                      <h5>ТЕЛЕГРАМ БОТ</h5>
                      <p>Телеграм бот для взаимодействия с пользователями-оцифровциками.</p>
                    </div>
                  </div>
                </motion.div>
              </motion.div>
              <motion.div
                variants={elemAnimate}
                id="item-2">
                <motion.div whileInView={{ scale: 0.85 }} whileHover={{ scale: 0.9 }} className="content-block">
                  <div className="content-title">
                    <h2 >Синхронизация ИС</h2>
                    <h5>Процесс обмена данных между информационными системами
                    </h5>
                  </div>
                </motion.div>
              </motion.div>
              <motion.div
                variants={elemAnimate}
                id="item-4">
                <motion.div whileInView={{ scale: 0.85 }} whileHover={{ scale: 0.9 }} className="content-block">
                  <div className="content-title">
                    <h2 >Распознавание номеров авто</h2>
                    <h5>Система автоматического опознания государственных номеров транспортных средств с камер видеонаблюдения
                    </h5>
                  </div>
                </motion.div>
              </motion.div>
              <motion.div
                variants={elemAnimate}
                id="item-5">
                <motion.div whileInView={{ scale: 0.85 }} whileHover={{ scale: 0.9 }} className="content-block">
                  <div className="content-title">
                    <h2 >ПАРЛАМЕНТ.KG</h2>
                    <h5>Аналитический портал для оценки результатов деятельности депутатов Жогорку Кенеша Кыргызской Республики на основе открытых данных.</h5>
                    <div className="content-list">
                      <div className="content-text">
                        <h5>WEB САЙТ </h5>
                        <p>Публичный web-сайт с аналитическими данными.</p>
                      </div>
                      <div className="content-text">
                        <h5>СИСТЕМА ВВОДА ДАННЫХ </h5>
                        <p>Модуль сбора открытых данных с официального сайта ЖК КР</p>
                      </div>
                      <div className="content-text">
                        <h5>ОТЧЕТЫ</h5>
                        <p>Динамическая аналитическая система  отчетов.</p>
                      </div>
                      <div className="content-text">
                        <h5>ГРАФИЧЕСКИЕ ИНСТРУМЕНТЫ </h5>
                        <p>Система графического представления результатов отчетной системы.</p>
                      </div>
                    </div>
                  </div>
                </motion.div>
              </motion.div>
            </motion.div>
            <br />
          </div>

          <div className="carousel">
            <a style={{fontSize:'1.5rem', fontWeight:'700', lineHeight:'2rem', textDecoration:'underline', margin:'2rem auto' }} href={pdfFile}>
              {t("present")}
            </a>
          </div>

        </div>
      </div>
    </section >
  );
}
