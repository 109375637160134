import React from "react";
import { motion } from "framer-motion";
import pdfFile from '../../assets/its31.pdf'
import { useTranslation } from "react-i18next";
import "./styles/sectionFive.css";
export default function SectionFive() {
  const { t } = useTranslation()
  const primaryAnimation = {
    offscreen: {
      y: -200,
      opacity: 0
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 1,
        delay: 0.2,
        stiffness: 120,
        type: 'tween',
      }
    }
  }
  const secondaryAnimation = {
    hidden: {
      translateX: -1500,
    },
    visible: {
      translateX: 0,
      transition: {
        duration: 1,
        bounce: 0.4,
        delay: 0.1,
        stiffness: 120,
        type: "tween"
      }
    }
  }

  return (
    <>
      <div className="realitive" style={{ width: '100%' }}>
        <div className="custom-shape-divider-top-1676300708 devider">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path fill="#f3f1ef" d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill"></path>
          </svg>
        </div>
      </div>
      <section className="section-five ">
        <div
          className="section-five_inside">
          <motion.div
            initial={"offscreen"}
            whileInView={'onscreen'}
            viewport={{ once: true, amount: 0.2 }}
            transition={{ staggerChildren: 0.2 }}
            id="contacts" className="section-five_inside-block">
            <motion.h3 variants={primaryAnimation}>{t("move")}</motion.h3>
            <motion.h2 variants={primaryAnimation}>{t("allow")}</motion.h2>
            <motion.div
              initial={"hidden"}
              whileInView={'visible'}
              viewport={{ once: true, amount: 0.2 }}
              transition={{ staggerChildren: 0.2 }}
              className="section-five_inside-contacts">
              <motion.div variants={secondaryAnimation} className="section-five_inside-contacts-left">
                <p><a href="mailto:office@its-tech.org">office@its-tech.org</a></p>
                <a href="tel:+996 312 973777">+996 312 973777</a>
                <span>{t("address")}</span>
              </motion.div>
              <motion.div variants={secondaryAnimation} className="section-five_inside-contacts-center">
                <a style={{color:"white"}} href="mailto:office@its-tech.org">
                <span>{t("send")}</span>
                </a>
              </motion.div>
              <motion.div variants={secondaryAnimation} className="section-five_inside-contacts-right">
                <a style={{color:"white"}} href={pdfFile}>
                  <span>{t("present")}</span>
                </a>
              </motion.div>
            </motion.div>
          </motion.div>

        </div>
      </section>
    </>
  );
}
