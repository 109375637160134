import React from "react";
import {m, motion} from "framer-motion";
import {forwardRef} from "react";
import {useTranslation} from "react-i18next";
import first from "../../assets/first.png";
import second from "../../assets/therd.png";
import third from "../../assets/second.png";
import "./styles/sectionTwo.css";

export default function SectionTwo() {
    const {t} = useTranslation();

    const sectionAnimation = {
        hidden: {
            y: -60,
            opacity: 0,
        },
        visible: custom => ({
            y: 0,
            opacity: 1,
            transition: {
                delay: custom * 0.3,
                type: 'spring',
                stiffness: 90,

            },
        })

    }
    const blockAnimation = {
        hidden: {
            x: -60,
            opacity: 0,
            scale: 2,

        },
        visible: custom => ({
            x: 0,
            opacity: 1,
            scale: 1,
            transition: {
                delay: custom * 0.5,
                duration: 1.5,
                type: "spring",
                stiffness: 80,
                ease: "linear"

            },
        })
    }
    const textAnimation = {
        hidden: {
            x: -40,
            opacity: 0,

        },
        visible: ({
            x: 0,
            opacity: 1,
            transition: {
                delay: 1,
                duration: 1,
                type: 'tween',
                ease: 'linear'
            },
        })

    }

    return (
        <>

            <motion.section
                className="section-two"
                initial="hidden"
                whileInView="visible"
                viewport={{amount: 0.2, once: true}}
            >
                <div id="about_us" className="section-two_inside">
                    <div className="section-two_inside-block">
                        <motion.h3 custom={1} variants={sectionAnimation}>{t("business")}</motion.h3>
                        <div initial="hidden"  viewport={{amount: 0.2, once: true}}
                             className="section-two_inside-images">
                            <motion.div custom={1} variants={blockAnimation} className="section-two_inside-images-left">
                                <img src={first} alt=""/>
                                <h3>{t('develop')}</h3>
                                <p>{t("presentation")}</p>
                            </motion.div>
                            <motion.div custom={2} variants={blockAnimation}
                                        className="section-two_inside-images-center">
                                <img src={second} alt=""/>
                                <h3>{t("tunduk")}</h3>
                                <p>{t("support")}</p>
                            </motion.div>
                            <motion.div custom={3} variants={blockAnimation}
                                        className="section-two_inside-images-right">
                                <img src={third} alt=""/>
                                <h3>{t('ecp')}</h3>
                                <p>{t("target")}</p>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </motion.section>

            <div className="realitive">
                <div className="section-two two">
                    <div className="section-two_inside">
                        <div className="section-two_inside-block" style={{paddingTop: "100px"}}>

                            <motion.h3 className="text">{t("create_websites")}</motion.h3>
                            <p className="itc-p text">{t("itc")}</p>
                            <h3 className="gap text">{t("funs")}</h3>
                            <h5 className="text">{t("braves")}</h5>
                            <p className="itc-p text">{t("other")}</p>
                            <div className="custom-shape-divider-top-1676300708">
                                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120"
                                     preserveAspectRatio="none">
                                    <path fill='#fffdfe'
                                          d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
                                          className="shape-fill"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
