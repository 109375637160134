import React from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import "./styles/sectionOne.css";
import video from "../../assets/video.webm";
import "./styles/animation.css";

export default function SectionOne() {
  const spanVariants = {
    hidden: {
      x: -20,
      opacity: 0,
    },
    visible: {
      x: 20,
      x: 0,
      opacity: 1,
    },
  };
  const headingVariants = {
    hidden: {
      x: -20,
      opacity: 0,
    },
    visible: {
      x: 0,
      opacity: 1,
    },
  };
  const linkVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
  };
  const blockVariants = {
    hidden: {
      x: 20,
      opacity: 0,
    },
    visible: {
      x: 0,
      opacity: 1,
    },
  };

  const { t } = useTranslation();
  return (
    <section className="section-one">
      <div className="section-one_inside">
        <div className="section-one_inside-left">
          <div
            className="svg-animation"
            style={{ width: "100%", height: "1%" }}
          >
            <div
              style={{
                width: "60%",
                height: "60%",
                position: "absolute",
                top: "6000%",
                left: "-40%",
                zIndex: "1",
              }}
            >

            </div>

            <div
              style={{
                width: "30%",
                height: "40%",
                position: "absolute",
                top: "-3950%",
                left: "45%",
                zIndex: "0",
              }}
            >

            </div>

            <motion.div
              initial={"hidden"}
              animate={"visible"}
              transition={{
                delay: 0.1,
                duration: 1,
              }}
              variants={spanVariants}
              style={{
                width: "18%",
                height: "18%",
                position: "absolute",
                top: "-2000%",
                left: "-18%",
                zIndex: "0",
              }}
            >

            </motion.div>

            <motion.div
              initial={"hidden"}
              animate={"visible"}
              transition={{
                delay: 0.1,
                duration: 1,
              }}
              variants={spanVariants}
              style={{
                width: "10%",
                height: "10%",
                position: "absolute",
                top: "-1500%",
                left: "-7%",
                zIndex: "0",
              }}
            >

            </motion.div>
          </div>
          <motion.span
            initial={"hidden"}
            animate={"visible"}
            transition={{
              delay: 0.1,
              duration: 1,
            }}
            variants={spanVariants}
            style={{ zIndex: "2", color: "#00A84F" }}
          >
            {t("development")}
          </motion.span>
          <motion.h1
            initial={"hidden"}
            animate={"visible"}
            transition={{
              delay: 0.1,
              duration: 0.5,
            }}
            variants={headingVariants}
            style={{ zIndex: "2" }}
          >
            {t("digital")}
          </motion.h1>
          <motion.div
            className="section-one_button"
            style={{ zIndex: "2", cursor: "pointer" }}
            initial={"hidden"}
            animate={"visible"}
            transition={{
              delay: 0.1,
              duration: 0.7,
              delay: 0.1,
              duration: 1,
              type: "spring",
              stiffness: 120,
            }}
            variants={linkVariants}
            whileHover={{
              originX: 0,
              scale: 1.1,
              transition: {
                duration: 0.2,
                type: "spring",
                stiffness: 120,
              },
            }}
          >
           <a href="mailto:office@its-tech.org">
              <span>{t("send")}</span>
           </a>
          </motion.div>
        </div>
        <motion.div
          className="section-one_inside-right"
          initial={"hidden"}
          animate={"visible"}
          transition={{
            delay: 0.1,
            duration: 0.5,
            delay: 0.1,
            duration: 1,
          }}
          variants={blockVariants}
        >
                <video  muted loop autoPlay width='500' height="500">
                    <source src={video} type="video/webm" />
                </video>
        </motion.div>

      </div>
    </section>
  );
}
