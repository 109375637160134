import getaround from '../assets/logos/UNDP_logo.svg.png'
import oyster from '../assets/logos/5f6d9d9813219540114628.svg'
import notarize from '../assets/logos/logo-2.png'
import superPhone from '../assets/logos/soros.png'

export const logos = [
   
    {
        id: 3,
        img:getaround,
        name:'first'

    },
    {
        id: 4,
        img:oyster,
        name:'first'

    },
    {
        id: 5,
        img:notarize,
        name:'first'

    },
    {
        id: 6,
        img:superPhone,
        name:'first'

    },
    {
        id: 3,
        img:getaround,
        name:'first'

    },
    {
        id: 4,
        img:oyster,
        name:'first'

    },
    {
        id: 5,
        img:notarize,
        name:'first'

    },
    {
        id: 6,
        img:superPhone,
        name:'first'

    },
    {
        id: 3,
        img:getaround,
        name:'first'

    },
    {
        id: 4,
        img:oyster,
        name:'first'

    },
    {
        id: 5,
        img:notarize,
        name:'first'

    },
    {
        id: 6,
        img:superPhone,
        name:'first'

    },
    {
        id: 3,
        img:getaround,
        name:'first'

    },
    {
        id: 4,
        img:oyster,
        name:'first'

    },
    {
        id: 5,
        img:notarize,
        name:'first'

    },
    {
        id: 6,
        img:superPhone,
        name:'first'

    },
    {
        id: 3,
        img:getaround,
        name:'first'

    },
    {
        id: 4,
        img:oyster,
        name:'first'

    },
    {
        id: 5,
        img:notarize,
        name:'first'

    },
    {
        id: 6,
        img:superPhone,
        name:'first'

    },
]