import React from "react";
import { motion } from "framer-motion";
import { AiFillLinkedin } from "react-icons/ai";
import { AiFillTwitterSquare } from "react-icons/ai";
import { AiFillFacebook } from "react-icons/ai";
import { AiFillYoutube } from "react-icons/ai";
import { AiFillBehanceSquare } from "react-icons/ai";
import { FiInstagram } from "react-icons/fi";
import logo from "../../assets/its13.png";
import instagram from "../../assets/instagram.svg";
import {
  Link,
  LinkItem,
  LinksWrapper,
  NavLinksContainer,
} from "../header/Header";
import { useTranslation } from "react-i18next";
import './Footer.css'

export default function Footer() {
  const date = new Date().getFullYear();
  const { t } = useTranslation();

  return (
    <footer>
      <div className="footer">
        <div className="footer-inside">
          <div className="footer-logo">
            <motion.a
              whileHover={{
                scale: 1.2,
              }}
              href="#"
            >
              <img src={logo} alt="" />
            </motion.a>
          </div>
          <div className="footer-links">
            <motion.a
              whileHover={{
                translateY: [0, -20, 0, -20, 0, -20, 0, -20, 0],
                transition: {
                  duration: 2,
                  stiffness: 120,
                },
              }}
              whileInView={{
                translateY: 0,
                transition: {
                  duration: 1,
                },
              }}
              href="#"
            >
              <img src={instagram} alt="instagram" />
            </motion.a>
            <motion.a
              whileHover={{
                translateY: [0, -20, 0, -20, 0, -20, 0, -20, 0],
                transition: {
                  duration: 2,
                  stiffness: 120,
                },
              }}
              whileInView={{
                translateY: 0,
                transition: {
                  duration: 1,
                },
              }}
              href="#"
            >
              <AiFillTwitterSquare color="#00acee" fontSize='34px' />
            </motion.a>
            <motion.a
              whileHover={{
                translateY: [0, -20, 0, -20, 0, -20, 0, -20, 0],
                transition: {
                  duration: 2,
                  stiffness: 120,
                },
              }}
              whileInView={{
                translateY: 0,
                transition: {
                  duration: 1,
                },
              }}
              href="#"
            >
              <AiFillFacebook color="#0165E1" fontSize='34px' />
            </motion.a>
          </div>
          <div className="footer-navlinks">
            <ul>
              <a href="#">
                <li>{t("main")}</li>
              </a>
              <a href="#about_us">
                <li>{t("about_us")}</li>
              </a>
              <a href="#portfolio">
                <li>{t("portfolio")}</li>
              </a>
              <a href="#contacts">
                <li>{t("contacts")}</li>
              </a>
            </ul>
          </div>
        </div>
      </div>
      <div className="copyright">
        <p>© ITS Technologies LLC - {date}</p>
      </div>
    </footer>
  );
}
